import React from "react"
import { Link } from "gatsby"

const Layout = ({children}) => {
      return (
        <div>
          <header className="bg-orange-600 text-white pl-3 pr-3 pb-3 pt-2">
          <div className="container mx-auto">
            <Link to="/">
            <h1 className="font-montserrat text-5xl mb-2">
              Maudit Français
            </h1>
            <div className="">
              Incisive and multiculturel since année 2000
            </div>
            </Link>
          </div>
        </header>
        <article>
          {children}
        </article>
        <p className="p-4 container mx-auto p-2 sm:p-0 mb-8"><Link to="#" >Haut de la page</Link> - <Link to="/">Page d'accueil</Link> - <Link to="/archives">Archives</Link></p>
      </div>
      )
    }
  
export default Layout
